
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import SectionHeader from '@/components/global/SectionHeader.vue';
import PrimaryButton from '@/components/global/PrimaryButton.vue';
import { webWatcher, intertextWeb } from '@/api/web';
import { useMeta } from 'vue-meta';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    SectionHeader,
    PrimaryButton,
  },
  setup() {
    const { t } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });
    const route = useRoute();

    const title = computed(
      () => `${t('navigation.intertexts')} ${t('global.sura')} ${route.params.sura} ${t('global.verse')} ${route.params.verse}`,
    );
    const description = computed(
      () => `${t('navigation.intertexts')} ${t('global.sura')} ${route.params.sura} ${t('global.verse')} ${route.params.verse}`,
    );
    const canonical = computed(
      () => `https://corpuscoranicum.org/${route.params.lang}/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/intertexts`,
    );
    const alternate_de = computed(
      () => `https://corpuscoranicum.org/de/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/intertexts`,
    );
    const alternate_en = computed(
      () => `https://corpuscoranicum.org/en/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/intertexts`,
    );
    const alternate_fr = computed(
      () => `https://corpuscoranicum.org/fr/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/intertexts`,
    );
    const meta = computed(() => ({
      title: title.value,
      description: description.value,
      link: [
        { rel: 'canonical', href: canonical.value },
        { rel: 'alternate', hreflang: 'de', href: alternate_de.value },
        { rel: 'alternate', hreflang: 'en', href: alternate_en.value },
        { rel: 'alternate', hreflang: 'fr', href: alternate_fr.value },
        { rel: 'alternate', hreflang: 'x-default', href: alternate_de.value },
      ],
      htmlAttrs: {
        lang: route.params.lang,
      },
    }));
    useMeta(meta);
    webWatcher(route, 'intertexts', intertextWeb);
    return { intertextWeb };
  },
});
